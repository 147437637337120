<template>
  <div class="center-vertical" style="flex-direction:column;height:300px">
    <img :src="img" alt="">
    <p>页面消失了</p>
  </div>
</template>

<script>
export default {
  name: 'noPage',
  data() {
    return {
      img: require('@/assets/images/icon/404.png')
    }
  }
}
</script>

<style scoped lang="less">
p{
  color:#bfbfbf;
  font-size: 13px;
  margin-top: 50px;
}
</style>