<template>
  <div class="camera-container">
    <div class="back" @click="goBack">
      <van-icon name="arrow-left" />
    </div>
    <div class="tips">
      请选择光线良好的环境，勿佩戴帽子、墨镜、口罩等遮挡物，拍摄提交个人五官照片。
    </div>
    <div class="video-wrapper">
      <div
        v-if="photo"
        :style="{backgroundImage: `url(${photo})`}"
        class="headImage"
      ></div>
      <div v-else class="video">
        <video  id="video" class="video" webkit-playsinline="true" playsinline="true" muted></video>
      </div>
    </div>
    <div class="photo-button photo-button_success" v-if="photo" type="primary" @click="savePhoto">
      <div class="text">
        <van-icon name="success" />
      </div>
    </div>
    <div class="photo-button" v-else type="primary" @click="takePhotoFn">
      <div class="text">
        <van-icon name="photograph" />
      </div>
    </div>
    <div class="reset" @click="handleStart" v-if="photo">重拍</div>
    
    <div class="nouse" @click="handleNoCamera">摄像功能用不了</div>
    <!-- <img class="bg" :src="require('@/assets/images/face-bg.png')" alt=""> -->
  </div>
</template>

<script>
import { Toast } from 'vant'
// import myLib from './index'
export default {
  name: 'Camera',
  data() {
    return {
      photo: ''
    }
  },
  mounted() {
    console.log(navigator.mediaDevices)
    this.connectCameraFn()
  },
  methods: {
    handleNoCamera() {
      this.$emit('nocamera')
    },
    handleStart() {
      this.photo = ''
      this.$nextTick(() => {
        this.connectCameraFn()
      }) 
    },
    savePhoto() {
      // const file = myLib.dataURLtoFile(this.photo, 'headimg')
      this.$emit('getFile', this.photo)
    },
    goBack() {
      this.$emit('goBack')
    },
    connectCameraFn() {
    
      if (!navigator) {
        Toast("navigator undefined")
      }
      if (navigator.mediaDevices === undefined) {
        Toast("mediaDevices undefined")
        navigator.mediaDevices = {}
      }
      if (navigator.mediaDevices.getUserMedia === undefined) {
        Toast("getUserMedia undefined")
        navigator.mediaDevices.getUserMedia = function(constraints) {
          // 首先，如果有getUserMedia的话，就获得它
          var getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia || navigator.oGetUserMedia;
          // 一些浏览器根本没实现它 - 那么就返回一个error到promise的reject来保持一个统一的接口
          if (!getUserMedia) {
            Toast('getUserMedia is not implemented in this browser')
            return Promise.reject(new Error('getUserMedia is not implemented in this browser'));
          }

          // 否则，为老的navigator.getUserMedia方法包裹一个Promise
          return new Promise(function(resolve, reject) {
            getUserMedia.call(navigator, constraints, resolve, reject);
          });
        }

      }

      // 当前在IE浏览器无法调用摄像头
      const constraints = { audio: false, video: { facingMode: 'user', width: 350, height: 350 } }
      const video = document.getElementById("video")

      navigator.mediaDevices.getUserMedia(constraints)
      .then(function(stream) {
        // 旧的浏览器可能没有srcObject
        if ("srcObject" in video) {
          video.srcObject = stream;
        } else {
          // 防止在新的浏览器里使用它，应为它已经不再支持了
          video.src = window.URL.createObjectURL(stream);
        }
        window.MediaStreamTrack = stream.getTracks()[0]
        video.onloadedmetadata = function() {
          video.play();
        };
      })
      .catch(function(err) {
        console.log(err.name + ": " + err.message);
      });
      // try {
      //   let Promise = navigator.mediaDevices.getUserMedia(constraints)
      //   Promise.then(MediaStream => {
      //     Toast("12321")
      //     if ("srcObject" in video) {
      //       video.srcObject = MediaStream
      //     } else {
      //       Toast("srcObject undefined",)
      //       video.src = window.URL.createObjectURL(MediaStream);
      //     }
      //     window.MediaStreamTrack = MediaStream.getTracks()[0]
      //     video.play()
      //   })
      // } catch (err) {
      //   Toast("浏览器不支持调用摄像头，请更换最新的谷歌浏览器，或者其他最新版",)
      // }
    },
    // 绘制照片
    takePhotoFn() {
      let video = document.getElementById("video")
      let w = video.offsetWidth
      let h = video.offsetHeight

      let canvas = document.createElement("canvas")
      canvas.width = w
      canvas.height = h
      let ctx = canvas.getContext("2d")
      // end
      ctx.drawImage(video, 0, 0, w, h)
      let base64 = canvas.toDataURL("image/jpeg", 1)
      this.photo = base64

      window.MediaStreamTrack &&
        window.MediaStreamTrack.stop &&
        window.MediaStreamTrack.stop(); // 关闭摄像头
    }
  }
}
</script>

<style lang="less" scoped>
  .camera-container {
    position: relative;
    height: 100vh;
    display: flex;
    flex-direction: column;
    background: #fff;
    // justify-content: center;
    align-items: center;
    .tips{
      position: absolute;
      font-size: 14px;
      color: #666;
      z-index: 2;
      top: 1.5rem;
      padding: 0 30px;
      font-weight: bold;
      line-height: 1.8;
      text-indent: 24px;
    }
    .back{
      position: absolute;
      font-size: 20px;
      color: #666;
      z-index: 2;
      top: 0.5rem;
      left: 0;
      padding: 0 20px;
      font-weight: bold;
    }
    .video-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      width: 240px;
      height: 240px;
      border-radius: 50%;
      overflow: hidden;
      margin-top: 3.5rem;
      object-fit: cover;
      .headImage {
        position: absolute;
        width: 100%;
        height: 100%;
        // margin-top: 3.5rem;
      }
      .video {
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        overflow: hidden;
        video {
          width: 100%;
          height: 100%;
          transform: scaleX(-1);
        }
        // margin-top: 3.5rem;
      }
    }
    .photo-button{
      position: absolute;
      bottom: 2.4rem;
      z-index: 2;
      height: 75px;
      width: 75px;
      font-size: 15px;
      border-radius: 50%;
      border: 1px solid #999;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px;
      .text{
        height: 100%;
        width: 100%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #ccc;
        border-radius: 50%;
        font-size: 25px;
      }
    }
    .photo-button_success {
      border-color: #07c160;
      .text {
        background: #07c160;
      }
    }
    .reset{
      right: 2rem;
      position: absolute;
      bottom: 2.4rem;
      z-index: 2;
      font-size: 15px;
      height: 75px;
      line-height: 75px;
      color: #666;
    }
    .bg{
      height: 100%;
      width: 100%;
      position: absolute;
      z-index: 1;
      left: 0;
      top: 0;
      object-fit: cover;
    }

    .nouse {
      right: 20px;
      position: absolute;
      bottom: 20px;
      z-index: 2;
      font-size: 12px;
      color: #1C3876;
      text-decoration: underline;
    }
  }
</style>
